import React, { useState, useEffect } from 'react';
import axios from "axios";
import { API_BASE_URL } from "../config/config";
import PageLoader from '../components/home/pageload';
import FrontVideo from "../components/homevideo";
import MetaTag from "../components/CommonSections/MetaTag";
import { Link } from "react-router-dom";


export default function LatestUpdates() {
     const [activeTab, setActiveTab] = useState(1);
     const handleTabClick = (tabNumber) => {
          console.log('tabNumber', tabNumber); 
          setActiveTab(tabNumber);
     };
     const handleClick = () => {
          window.scrollTo(0, 0);
     }
     useEffect(() => {
          getData();
     }, [])

     const [latestUpdate, setLatestUpdate] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);
     const [getTagList, setTagList] = useState(null);
     const [getBlogList, setBlogList] = useState(null);
     const [getBlogFilterList, setBlogFilterList] = useState(null);
     const [selectedTags, setSelectedTags] = useState([]);

     const handleCheckboxChange = (e) => {
         const tagId = parseInt(e.target.value);
         setSelectedTags((prevSelectedTags) => 
             e.target.checked
                 ? [...prevSelectedTags, tagId]  
                 : prevSelectedTags.filter(tag => tag !== tagId)  
         );

         setBlogFilterList((prevBlogList) => {
             const newSelectedTags = e.target.checked
                 ? [...selectedTags, tagId]
                 : selectedTags.filter(tag => tag !== tagId);
             
             if (newSelectedTags.length === 0) return getBlogList;

             return getBlogList.filter((offer) => {
                 const blogTagIds = offer?.tags?.map(tag => tag.id) || [];
                 return blogTagIds.some(tagId => newSelectedTags.includes(tagId));
             });
         });
     };


     const handleSelectChange = (e) => {
          const selectedTagId = parseInt(e.target.value);
          
          // If no tag is selected, show all blogs
          if (!selectedTagId) {
              setBlogFilterList(getBlogList);
              return;
          }
          
          // Filter blogs based on the selected tag ID
          const filteredBlogs = getBlogList.filter((offer) => {
              const blogTagIds = offer?.tags?.map(tag => tag.id) || [];
              return blogTagIds.includes(selectedTagId);
          });
      
          setBlogFilterList(filteredBlogs);
      };
      

     const getRandomClass = () => {
          const classes = ['trends', 'tt', 'dd'];
          return classes[Math.floor(Math.random() * classes.length)];
     };
      
     const getData = () => {

          axios.post(`${API_BASE_URL}/api/latestupdates/getAllLatestUpdates`).then((response) => {
               setTagList(response?.data?.data?.latest_updates?.blog_tags);
               setLatestUpdate(response);
               setActiveTab(response?.data?.data?.latest_updates?.latest_updates_menu_data[0].id)

               const generalSettingsData = response?.data?.data?.latest_updates?.latest_updates_banner_data;

               const BlogSettingsData = generalSettingsData.find((item) => item.tag === "latest_updtate") || null;
               const BlogSettingsResponse = JSON.parse(BlogSettingsData?.response || "{}");
               setSettingResponse(BlogSettingsResponse);



               const blogList = response.data.data.latest_updates.latest_updates_data.map((offer, index) => {
                   let tags = [];
                   try {
                    if (offer.tag_ids && offer.tag_ids.trim() !== "") {
                         tags = JSON.parse(offer.tag_ids).map(tag => ({
                             id: tag.value,  
                             name: tag.label  
                         }));
                     }
                   } catch (error) {
                       console.error('Error parsing tag_ids for offer', offer.id, error);
                   }
   
                   return {
                       id: offer.id,
                       title: offer.title,
                       description: offer.description,
                       image: offer.image,
                       button_url: offer.button_url,
                       tags, 
                       parent_id: offer.parent_id,
                       altText: offer.altText,
                   };
               });
               setBlogList(blogList);
               setBlogFilterList(blogList);
               console.log('Transformed newArray:', blogList);
               
          }).catch((error) => {
               <PageLoader />
          })
     }

     // Function to truncate the description
     function truncateDescription(description) {
          if (!description) {
               return '';
          }

          // Split the description into words
          const words = description.split(' ');

          // Check if there are more than 11 words
          if (words.length > 10) {
               const truncatedText = words.slice(0, 11).join(' ');
               const truncatedWithEllipsis = truncatedText + '...';
               return truncatedWithEllipsis;
          } else {
               return description;
          }
     }
     return (
          <>
          {latestUpdate &&
          <>
               <MetaTag data={latestUpdate} pageKey="latest_updates"/>
               {latestUpdate?.data?.data?.latest_updates?.latest_updates_banner_data.length > 0 &&
                    <>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   {settingResponse?.title != "" && 
                                   <h1>{settingResponse?.title}</h1>
                                   }
                                   {settingResponse?.sub_title != "" && 
                                   <p>{settingResponse?.sub_title}
                                        <span>{settingResponse?.italic}</span>
                                   </p>
                                   }
                              </div>
                         </div>
                    </>
               }
               {latestUpdate?.data?.data?.latest_updates?.latest_updates_information_data.length > 0 &&
                    <div className="sec-staying-ahead section-space">

                         <div className="container" dangerouslySetInnerHTML={{ __html: latestUpdate?.data?.data?.latest_updates?.latest_updates_information_data[0]?.description }}>
                         </div>
                    </div>
               }


               <div className="weoffer weoffer-white section-space spin-wrap">
                    <div className="spin-round-wrap">
                         <div className="spin-round color-navy-blue">
                              <span className='ballDot1'>1</span>
                              <span className='ballDot2'>2</span>
                              <span className='ballDot3'>3</span>
                              <span className='ballDot4'>4</span>
                         </div>
                    </div>
                    {latestUpdate?.data?.data?.latest_updates?.latest_updates_menu_data.length > 0 &&
                         <div className="container">
                              <div className="weoffer-inner">
                                   <div className="weoffer-inner-tab">
                                        <ul className="tabs shortest-tabs">

                                             {latestUpdate?.data?.data?.latest_updates?.latest_updates_menu_data.map((offer, index) => (

                                                  <li key={index} className={activeTab === offer.id ? "actived" : ""}>
                                                       <button
                                                            className={activeTab === offer.id ? "tab-btn tab-btn-bgdark active" : "tab-btn tab-btn-bgdark"}
                                                            onClick={() => handleTabClick(offer.id)} tabIndex={0}
                                                       >
                                                            {offer.title}
                                                       </button>
                                                  </li>

                                             ))}
                                        </ul>
                                   </div>
                                   <div className="weoffer-details">
                                        <div className="tab-content tab-content-bgdark">
                                             <div className="blogname">

                                                  {activeTab === 13 ? 
                                                  
                                                  <div className='card-contain'>
                                                       
                                                       <div className='check-contain'>
                                                            <div className='web-block-only'>
                                                                 <h5>Filter by Topic</h5>
                                                                 {getTagList.map((tag, index) => (
                                                                      <div className="check" key={index}>
                                                                           <input 
                                                                                type="checkbox" 
                                                                                id={`check-${tag.id}`} 
                                                                                value={tag.id} 
                                                                                onChange={handleCheckboxChange} 
                                                                           />
                                                                           <label htmlFor={`check-${tag.id}`}>{tag.name}</label>
                                                                      </div>
                                                                 ))}
                                                            </div>

                                                            <div className="mob-block-only">
                                                                 <select name="" id="" className='input-select' onChange={handleSelectChange}>
                                                                 <option value="">Filter By Topic</option>
                                                                 {getTagList.map((tag, index) => (
                                                                      <option value={tag.id}>{tag.name}</option>
                                                                 ))}
                                                                 </select>
                                                            </div>
                                                            
                                                       </div>
                                                       <div className="card-contain-inner">
                                                       {getBlogFilterList?.map((offer, index) => (
                                                            <React.Fragment key={index}>
                                                                 {activeTab === offer.parent_id && (
                                                                      <>
                                                                           <div className="blog-inner">
                                                                                <Link to={`/latest-updates${offer?.button_url}`}>
                                                                                <img src={offer?.image} alt={offer?.altText} height={190} width={368} loading="lazy" />
                                                                                </Link>
                                                                                <div className="blog-text list-summery">
                                                                                <Link className="action-link" to={`/latest-updates${offer?.button_url}`}>
                                                                                     <h5>{offer?.title}</h5>
                                                                                </Link>
                                                                                <div dangerouslySetInnerHTML={{ __html: truncateDescription(offer?.description) }}></div>

                                                                                <div className="filter-pill-contain">
                                                                                {offer.tags?.map((offer, index) => (
                                                                                     <div key={offer.id} className={`filter-pill ${getRandomClass()}`}>{offer.name}</div>
                                                                                ))}
                                                                                </div>

                                                                                <Link to={`/latest-updates${offer?.button_url}`}>
                                                                                     <button className="button btn-grayish" onClick={handleClick}>
                                                                                          Know more
                                                                                     </button>
                                                                                </Link>
                                                                                </div>
                                                                           </div>
                                                                      </>
                                                                 )}
                                                            </React.Fragment>
                                                            ))}

                                                       </div>
                                                  </div>
                                                  
                                                  : null}

                                                  {latestUpdate?.data?.data?.latest_updates?.latest_updates_data.map((offer, index) => (
                    
                                                       <React.Fragment key={index}>
                                                            {activeTab === offer.parent_id && (
                                                            <>
                                                                 {activeTab != 13 ?  
                                                                 <div className="blog-inner">
                                                                      <Link to={`/latest-updates${offer?.button_url}`}>
                                                                           <img src={offer?.image} alt={offer?.altText} height={190} width={368} loading="lazy"/>
                                                                      </Link>
                                                                      <div className="blog-text list-summery">
                                                                           <Link className="action-link" to={`/latest-updates${offer?.button_url}`}>
                                                                                <h5>{offer?.title}</h5>
                                                                           </Link>
                                                                           {/* Truncate the description here */}
                                                                           <div dangerouslySetInnerHTML={{ __html: truncateDescription(offer?.description) }}></div>
                                                                           {/* <div className="filter-pill-contain">
                                                                                <div className="filter-pill">
                                                                                     Cyber Security
                                                                                </div>
                                                                                <div className="filter-pill tt">
                                                                                     Tech Trends
                                                                                </div>
                                                                                <div className="filter-pill trends">
                                                                                     Trending
                                                                                </div>
                                                                           </div> */}
                                                                           <Link to={`/latest-updates${offer?.button_url}`}>
                                                                                <button className="button btn-grayish" onClick={handleClick}>
                                                                                     Know more
                                                                                </button>
                                                                           </Link>
                                                                      </div>
                                                                 </div>
                                                                 : null}
                                                            </>
                                                            )}
                                                      
                                                       </React.Fragment>
                                                  ))}

                                                  {latestUpdate?.data?.data?.latest_updates?.latest_updates_data.every(offer => activeTab !== offer.parent_id) && (
                                                       <p>Data not found</p>
                                                  )}



                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    }
                    <div className="spin-round-wrap">
                         <div className="spin-round">
                              <span className='ballDot1'>1</span>
                              <span className='ballDot2'>2</span>
                              <span className='ballDot3'>3</span>
                              <span className='ballDot4'>4</span>
                         </div>
                    </div>
               </div>
               </>
               }
          </>
     );
}