import React, { useEffect, useState } from "react";
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import ClientExperiences from "../../components/home/client-experiences";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import Advantage from "../../components/CommonSections/Advantage";
import PageLoader from "../../components/home/pageload";
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgSmartCity from "../../assest/images/IshanSmartCity.png";
import { Link } from "react-router-dom";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";

export default function SmartCityServices() {
     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     const [smartCityData, setSmartCityData] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);

     useEffect(() => {
          const requestData = {
               "service_tag": "smart_city"
          };

          axios.post(`${API_BASE_URL}/api/smartcity/getsmartCity`, requestData).then((response) => {

               setSmartCityData(response);

               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;

               const networkServicesSettingsData = generalSettingsData.find((item) => item.tag === "smart_city") || null;
               const networkServicesSettingsResponse = JSON.parse(networkServicesSettingsData?.response || "{}");

               setSettingResponse(networkServicesSettingsResponse);
          }).catch((error) => {
               <PageLoader />
          })
     }, [])


     return (

          <>
               {smartCityData === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <SubServiceSchema title={smartCityData?.data?.data?.smart_city?.smart_city_banner_data
                              [0].title} url='https://ishantechnologies.com/systems-integration/smart-city-services' parentTitle='System Integration' parentUrl='https://ishantechnologies.com/systems-integration' />
                    <MetaTag data={smartCityData} pageKey="smart_city"/>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   <HeaderTitle headerTitledata={smartCityData} pageKey="smart_city" />
                              </div>
                         </div>
                         <div className={`video hero-banner ${
                              !smartCityData?.data?.data?.smart_city?.smart_city_banner_data[0].banner_title &&!smartCityData?.data?.data?.smart_city?.smart_city_banner_data[0].banner_img_desc 
                                   ? 'no-content' 
                                   : ''
                              }`}
                              >
                              <FrontVideo homeData={smartCityData} pageKey="smart_city" />
                      
                              <div className="banner-content ">
                                   {smartCityData?.data?.data?.smart_city?.smart_city_banner_data[0].banner_title && (
                                   <h2 className='home-heading'>
                                             {smartCityData?.data?.data?.smart_city?.smart_city_banner_data
                              [0].banner_title}
                                   </h2>
                                   )}

                                   {smartCityData?.data?.data?.smart_city?.smart_city_banner_data[0].banner_img_desc && (
                                   <p className="para">
                                             {smartCityData?.data?.data?.smart_city?.smart_city_banner_data
                              [0].banner_img_desc}
                                   </p>
                                   )}

                                   {smartCityData?.data?.data?.smart_city?.smart_city_banner_data[0].button_img_name && (
                                   <a href={smartCityData?.data?.data?.smart_city?.smart_city_banner_data
                              [0].button_url}>
                                             <button className="button hero-btn">
                                             {smartCityData?.data?.data?.smart_city?.smart_city_banner_data[0].button_img_name}
                                             </button>
                                   </a>
                                   )}
                              </div>
                         </div>

                         <div className="empowering section-space spin-wrap">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              <div className="container">
                                   <div className="empowering-inner">
                                        <div className="empowering-left">
                                             {<img src={ImgSmartCity} alt="Smart City" height={60} width={60} loading="lazy"/>}
                                        </div>
                                        <div className="empowering-right" dangerouslySetInnerHTML={{ __html: smartCityData?.data?.data?.smart_city?.smart_city_information_data[0]?.description }}>
                                        </div>
                                   </div>
                              </div>

                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                         </div>

                         <div className="weoffer-bgdark">
                              <WhatWeOffer title={settingResponse?.what_we_offer} data={smartCityData?.data?.data?.smart_city?.smart_city_offer_data} />
                         </div>

                         <Advantage title={settingResponse?.advantages} data={smartCityData?.data?.data?.smart_city?.smart_city_advantages_data} />

                         <div className="client-experiences section-space">
                              <ClientExperiences homeData={smartCityData} pageKey="smart_city" tag="smart_city" />
                         </div>
                         <div className="supercharge supercharge-left">
                              <div className="container">
                                   <h2 className="supercharge-heading">{smartCityData?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.title}</h2>
                                   <div className="supercharge-btns">

                                        {smartCityData?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1 &&
                                             <Link to={smartCityData?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_url_1}>
                                                  <button className="getintouch" onClick={handleClick}>{smartCityData?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1?.toUpperCase()}</button>
                                             </Link>
                                        }
                                        {smartCityData?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2 &&
                                             <Link to={smartCityData?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_url_2} target="_blank">
                                                  <button className="getintouch" onClick={handleClick}>{smartCityData?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2?.toUpperCase()}</button>
                                             </Link>
                                        }
                                   </div>
                              </div>
                         </div>
                    </>
               )}
          </>
     );
}