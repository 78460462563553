import React, { useEffect, useState } from "react";

import axios from "axios";
import { API_BASE_URL } from "../config/config";
import PageLoader from "../components/home/pageload";
import MetaTag from "../components/CommonSections/MetaTag";
import DownloadIcon from "../assest/images/download-icon.png";
import { Link } from "react-router-dom";
export default function DownloadsResources() {
     useEffect(() => {
          getData();
          getCategoryListAndResourceTyeps();
     }, [])
     const [downloadData, setDownloadData] = useState(null);
     const [metaData, setMetadata] = useState(null);
     const [bannerTitle, setBannerTitle] = useState(null);
     const [getGroupedMedia, setGroupedMedia] = useState(null);

     const [getResourceTypeList, setResourceTypeList] = useState([]);
     const [getCategoryList, setCategoryList] = useState([]);

     const [getActiveCategoryTab, setActiveCategoryTab] = useState(0); 
     const [getActiveTypeTab, setActiveTypeTab] = useState(0); 

     const getData = () => {
          axios.post(`${API_BASE_URL}/api/download/getAlldownload`).then((response) => {
               console.log('All Resources', response); 
               if (response.data.error === false) {
                    setDownloadData(response.data.data);
                    setMetadata(response);
                    const bannerTitleResponse = JSON.parse(response.data.data?.download?.banner_data[0]?.response || "{}");
                    setBannerTitle(bannerTitleResponse);
               }
          }).catch((error) => {
               <PageLoader />
          })
     }

     const getCategoryListAndResourceTyeps = () => {
          axios.post(`${API_BASE_URL}/api/download/getCategories`).then((response) => {
               const categoryList = response.data.data.map(cat => ({
                 value: cat.id,
                 label: cat.name,
               }));

               setCategoryList(categoryList);
          });

          axios.post(`${API_BASE_URL}/api/download/getResourceTypes`).then((response) => {
               const resourceType = response.data.data.map(rtype => ({
                 value: rtype.id,
                 label: rtype.name,
               }));
               setResourceTypeList(resourceType);
     
          });
     }

     const handleTypesTabs = (index) => {
          console.log('Active Type : ', index);
          setActiveTypeTab(index); 
     };

     const handleCategoriesTabs = (index) => {
          console.log('Active Category : ', index);
          setActiveTypeTab(0); 
          setActiveCategoryTab(index); 
      };


      const handleCategoryChange = (event) => {
          const selectedCategory = event.target.value;
          setActiveTypeTab(0); 
          setActiveCategoryTab(selectedCategory); 
     };

     console.log('getActiveCategoryTab', getActiveCategoryTab);
     console.log('getActiveTypeTab', getActiveTypeTab)
     return (
          <>
          {downloadData !== null &&
          <>
               <MetaTag data={metaData} pageKey="download"/>
               <div className="page_heading responsive_heading">
                    <div className="container">
                         <h1>{bannerTitle?.title}</h1><p>{bannerTitle?.sub_title}<span>{bannerTitle?.italic}</span></p>
                    </div>
               </div>
               <div className="downloadsresources md-section-space">
                    <div className="container">
                         <div className="dnr-top-nav">
                         <div className="nav-contain">
                         {Array.isArray(getResourceTypeList) && getResourceTypeList.length > 0 ? (
                              getResourceTypeList.map((tag, index) => (
                                   <div
                                        key={index}
                                        className={`nav nav-${tag.value} ${getActiveTypeTab === tag.value ? 'active' : ''}`}
                                        onClick={() => handleTypesTabs(tag.value)}
                                   >
                                        {tag.label}
                                   </div>
                              ))
                              ) : (
                              <div>No resource types available</div>
                         )}
                         </div>
                         </div>
                         <div className="dnr-contain">
                              <div className="dnr-filter">
                                   <ul className="filter-list web-block-only">
                                   {getCategoryList?.length > 0 ? (
                                        getCategoryList.map((tag, index) => (
                                             <li
                                                  key={index}
                                                  className={`nav-${tag.value} ${getActiveCategoryTab === tag.value ? 'active' : ''}`}
                                                  onClick={() => handleCategoriesTabs(tag.value)}
                                             >
                                                  {tag.label}
                                             </li>
                                        ))
                                        ) : (
                                        <li>No categories available</li>
                                        )}
                                   </ul>
                                   <select
                                        name="categories"
                                        id="categories"
                                        className="input-select mob-block-only"
                                        onChange={handleCategoryChange} // Use onChange to handle selection
                                        value={getActiveCategoryTab} // Bind the selected value to the state
                                        >
                                        <option value="0">Filter By Categories</option>
                                        {getCategoryList.map((tag, index) => (
                                        <option
                                             key={index}
                                             value={tag.value} // Use value to pass the tag's value
                                        >
                                             {tag.label}
                                        </option>
                                        ))}
                                   </select>

                              </div>
                              <div className="dwnld">
                              <ul>
                              {downloadData?.download?.download_data
                                   .filter(tag => {
                                   const categoryIds = tag.Categories_ids ? tag.Categories_ids.split(',').map(id => id.trim()) : [];
                                   const selectedCategory = getActiveCategoryTab ? String(getActiveCategoryTab).trim() : "";

                                   const categoryMatch = !selectedCategory || selectedCategory === '0' || categoryIds.includes(selectedCategory);

                                   const resourceTypeIds = tag.resource_type_ids ? tag.resource_type_ids.split(',').map(id => id.trim()) : [];
                                   const selectedResourceType = getActiveTypeTab ? String(getActiveTypeTab).trim() : "";
                                   const resourceTypeMatch = !selectedResourceType || selectedResourceType === '0' || resourceTypeIds.includes(selectedResourceType);

                                   return categoryMatch && resourceTypeMatch;
                                   })
                                   .map((tag, index) => (
                                        <li key=''>
                                             {tag.video_url ? 
                                             <div className="img-contain">
                                                       <iframe width="949" height="534" src={`https://www.youtube.com/embed/${tag.video_url}`} title="Ishan DC-1 Inaugural Ceremony | Data Center | Colocation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                                  </div>
                                             : <div className="img-contain">
                                                  <img src={tag.image} alt={tag.altText} height={375} width={275} loading="lazy" />
                                               </div>
                                             }
                                             
                                             <div className="dwnld-details">
                                        {tag.video_url ? 
                                             <Link to={`https://www.youtube.com/embed/${tag.video_url}`} target="_blank">
                                                  {tag.name} 
                                                  
                                             </Link> : 
                                             <Link to={tag.pdfFile} target="_blank">
                                                  {tag.name} 
                                              <img src={DownloadIcon} alt="icon" loading="lazy" />
                                             </Link>
                                        } 
                                                  
                                             </div>
                                        </li>
                                    ))}
                                   
                              </ul>
                              </div>
                         </div>
                         
                         
                         {/* <div className="downloadsresources">
                              <div id="container">
                                   {renderMediaSection("Videos", getGroupedMedia.videos, "video_url")}
                                   {renderMediaSection("Files", getGroupedMedia.files, "pdfFile")}
                                   {renderMediaSection("Images", getGroupedMedia.images, "image_url")}
                              </div>
                         </div> */}


                    </div> 
               </div>
          </>
          }
          </>
     );
}