import React, { useState, useEffect } from 'react';
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import Advantage from "../../components/CommonSections/Advantage";
import CtaBanner from "../../components/CommonSections/CtaBanner";
import PageLoader from '../../components/home/pageload';
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgManagedCloudServices from "../../assest/images/IshanManagedCloudServices.png";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";

export default function CloudServices() {
     useEffect(() => {
          getData();
     }, [])

     const [managedCloudServicesData, setManagedCloudServicesData] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);

     const getData = () => {
          axios.post(`${API_BASE_URL}/api/managedcloudservices/getManagedCloudServices`).then((response) => {

               setManagedCloudServicesData(response);

               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;

               const managedCloudServicesSettingsData = generalSettingsData.find((item) => item.tag === "managed_cloud_services_advantage") || null;
               const managedCloudServicesSettingsResponse = JSON.parse(managedCloudServicesSettingsData?.response || "{}");
               managedCloudServicesSettingsResponse.advantages = managedCloudServicesSettingsResponse;
               setSettingResponse(managedCloudServicesSettingsResponse);
          }).catch((error) => {
               <PageLoader />
          })
     }
     return (

          <>

               {managedCloudServicesData === null ? (
                    <PageLoader />
               ) : (
                    <>
                     <SubServiceSchema title={managedCloudServicesData?.data?.data?.managed_cloud_services?.managed_cloud_services_banner_data
                              [0].title} url='https://ishantechnologies.com/data-center-services/cloud-services' parentTitle='Data Centre and Cloud Services' parentUrl='https://ishantechnologies.com/data-center-services' />
                         <MetaTag data={managedCloudServicesData} pageKey="managed_cloud_services"/>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   <HeaderTitle headerTitledata={managedCloudServicesData} pageKey="managed_cloud_services" />
                              </div>
                         </div>
                         <div 
                              className={`video hero-banner ${
                              !managedCloudServicesData?.data?.data?.managed_cloud_services?.managed_cloud_services_banner_data
                                                            [0]?.banner_title &&!managedCloudServicesData?.data?.data?.managed_cloud_services?.managed_cloud_services_banner_data
                                                            [0]?.banner_img_desc
                                   ? 'no-content' 
                                   : ''
                              }`}
                              >
                              <FrontVideo homeData={managedCloudServicesData} pageKey="managed_cloud_services" />

                              <div className="banner-content ">
                              {managedCloudServicesData?.data?.data?.managed_cloud_services?.managed_cloud_services_banner_data
                              [0]?.banner_title && (
                              <h2 className='home-heading'>
                                   {managedCloudServicesData?.data?.data?.managed_cloud_services?.managed_cloud_services_banner_data
                              [0].banner_title}
                              </h2>
                              )}

                              {managedCloudServicesData?.data?.data?.managed_cloud_services?.managed_cloud_services_banner_data
                              [0]?.banner_img_desc && (
                              <p className="para">
                                   {managedCloudServicesData?.data?.data?.managed_cloud_services?.managed_cloud_services_banner_data
                              [0].banner_img_desc}
                              </p>
                              )}

                              {managedCloudServicesData?.data?.data?.managed_cloud_services?.managed_cloud_services_banner_data
                              [0]?.button_img_name && (
                              <a href={managedCloudServicesData?.data?.data?.managed_cloud_services?.managed_cloud_services_banner_data
                              [0].button_url}>
                                   <button className="button hero-btn">
                                   {managedCloudServicesData?.data?.data?.managed_cloud_services?.managed_cloud_services_banner_data
                              [0]?.button_img_name}
                                   </button>
                              </a>
                              )}
                              </div>  
                         </div>


                         <div className="empowering section-space spin-wrap">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              <div className="container">
                                   <div className="empowering-inner">
                                        <div className="empowering-left">
                                             {<img src={ImgManagedCloudServices} alt="Managed Cloud Services" height={60} width={60} loading="lazy"/>}
                                        </div>
                                        <div className="empowering-right" dangerouslySetInnerHTML={{ __html: managedCloudServicesData?.data?.data?.managed_cloud_services?.managed_cloud_services_information_data[0]?.description }}>
                                        </div>
                                   </div>
                              </div>
                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                         </div>

                         <div className="weoffer-bgdark">
                              <WhatWeOffer title={settingResponse?.what_we_offer} data={managedCloudServicesData?.data?.data?.managed_cloud_services?.managed_cloud_services_offer_data} />
                         </div>

                         <Advantage title={settingResponse?.advantages} data={managedCloudServicesData?.data?.data?.managed_cloud_services?.managed_cloud_services_advantages_data} />

                         <CtaBanner bannerData={managedCloudServicesData?.data?.data?.managed_cloud_services?.managed_cloud_services_cta_banner_data} />
                    </>
               )}
          </>

     );
}